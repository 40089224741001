<template>
  <AppTopBar/>
  <div class="app-content">
    <router-view v-slot="{ Component, route }">
      <template v-if="Component">
        <TransitionFade>
<!--          <KeepAlive>-->
            <div :key="route.name" class="router-item">
              <Suspense>
                <component :is="Component"/>
                <template #fallback>
                  <Loading/>
                </template>
              </Suspense>
            </div>
<!--          </KeepAlive>-->
        </TransitionFade>
      </template>
    </router-view>
  </div>
  <div class="screen-lock" :class="{'hidden': processing === 0}">
    <Loading/>
  </div>
  <Modal v-if="show_timeout_warning">
    <template #header>
      Auth Timeout Warning
    </template>
    <template #body>
      <span>You are about to be logged out.</span>
      <span>Stay signed in?</span>
    </template>
    <template #footer>
      <button class="black" @click="store.dispatch('auth/refresh')">Yes</button>
      <button class="black" @click="store.dispatch('auth/logout')">No</button>
    </template>
  </Modal>
</template>

<script setup>
import {computed, provide, ref} from "vue";
import {useStore} from "vuex";
import AppTopBar from "@/components/AppTopBar";
import Modal from "@/components/Modal";
import TransitionFade from "@/components/TransitionFade";
import Loading from "@/components/Loading";

const store = useStore();
const show_timeout_warning = computed(() => store.state.auth.show_timeout_warning);
const processing = computed(() => store.state.processing);

</script>


<style lang="less">
@import "@/assets/theme-vars";

#app {
  background: @whitish;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.app-content {
  flex-direction: row;
  flex: 1 1 0;
  overflow: hidden;
  background-color: @whitish;
}

.router-item {
  flex: 1 0 0;
  overflow: hidden;
}

.screen-lock {
  position: fixed;
  z-index: @screen_lock_z_index;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
}

.screen-lock.hidden {
  visibility: hidden;
}
</style>