import {createLogger, createStore} from 'vuex'
import auth from '@/store/modules/auth'
import brands from "@/store/modules/brands";
import service_monitoring from "@/store/modules/service_monitoring";
import tools from "@/store/modules/tools";
import {auth_api, blocking_auth_api} from "@/axios";

const debug = process.env.NODE_ENV !== 'production'

const state = () => ({
    processing: 0,
    countries: null
})

const getters = {}

const actions = {
    load_countries({commit, state}, {blocking=false}={}) {
        if (state.countries)
            return
        return (blocking ? blocking_auth_api : auth_api).get("/api/data/countries")
            .then(resp => commit('set_countries', resp.data));
    }
}

const mutations = {
    get_processing_lock(state) {
        state.processing += 1
    },
    release_processing_lock(state) {
        state.processing -= 1
    },
    set_countries(state, countries) {
        state.countries = countries;
    }
}

export default createStore({
    modules: {
        auth, brands, service_monitoring, tools
    },
    state,
    actions,
    getters,
    mutations,
    strict: debug,
    plugins: debug ? [createLogger()] : []
})