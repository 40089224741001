import axios from 'axios'
import store from "@/store";
import router from "@/router";
import {useToast} from "vue-toastification";

const toast = useToast();

const api_conf = {
    headers: {'X-Source': `${window.location.protocol}${window.location.host}`}
};
// const auth_request = req => {
//     if (store.state.auth.token)
//         req.headers['X-Auth'] = store.state.auth.token;
//     return req;
// }
const resp_success = resp => {
    let cookies = document.cookie.split('; ').reduce((prev, current) => {
        const [name, ...value] = current.split('=');
        prev[name] = value.join('=');
        return prev;
    }, {});
    const token = cookies['X-Auth'];
    if (token)
        store.dispatch('auth/refresh_token_and_timeouts', token)
    return resp;
}
const resp_err = err => {
    if (store.state.auth.data && store.state.auth.data.exp * 1000 < Date.now()) {
        store.dispatch('auth/logout')
        const target = {name: 'login'}
        if (router.currentRoute.value.path !== '/')
            target.query = {path: router.currentRoute.value.path}
        router.replace(target);
    }
    if (Math.floor(err.response.status / 100) === 4 && err.response.data.error_message) {
        toast.error(err.response.data.error_message);
    } else {
        toast.error("ERROR - Contact rick.gentry@crispin.com for assistance.")
        console.log(err);
    }
    throw err;
}

export const auth_api = axios.create(api_conf);
// auth_api.interceptors.request.use(auth_request);
auth_api.interceptors.response.use(resp_success, resp_err);

export const blocking_auth_api = axios.create(api_conf);
blocking_auth_api.interceptors.request.use(req => {
    store.commit('get_processing_lock');
    return req;//auth_request(req);
});
blocking_auth_api.interceptors.response.use(resp => {
    store.commit('release_processing_lock');
    return resp_success(resp);
}, err => {
    store.commit('release_processing_lock');
    resp_err(err);
});