import router from "@/router";
import {auth_api} from "@/axios";
import {useToast} from "vue-toastification";

const toast = useToast();

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

const local_storage_token_key = 'auth_token';

const state = () => ({
    token: null,
    data: null,
    change_password_token: null,
    logout_warning_timeout: null,
    logout_timeout: null,
    show_timeout_warning: false
})

const getters = {}

const actions = {
    async init(context) {
        let token = localStorage.getItem(local_storage_token_key);
        if (token) {
            let token_body = parseJwt(token);
            if (token_body.exp < Math.floor(Date.now() / 1000))
                token_body = null;
        }

        if (token)
            await context.dispatch('refresh_token_and_timeouts', token);

        if (context.state.token) {
            context.dispatch('refresh');
            context.dispatch('brands/load_brands', {}, {root: true});
            //context.dispatch('tools/load_tools', null, {root: true});
        }

        return Promise.resolve();
    },
    login(context, credentials) {
        auth_api.post("/api/user/login", credentials)
            .then(async () => {
                await context.dispatch('brands/load_brands', {}, {root: true});
                const return_path = router.currentRoute.value.query.path;
                const current_route_name = router.currentRoute.value.name;
                if (return_path) {
                    router.replace(return_path);
                } else if (current_route_name !== undefined) {
                    router.push({name: 'home'});
                }
            })
            .catch(err => {
                switch (err.response.data.result) {
                    case 'password_expired':
                        setTimeout(() => context.commit('set_change_password_token', err.response.data.stateToken), 2000);
                        break;
                }
            });
    },
    refresh(context) {
        auth_api.get("/api/user/login_refresh")
            .then(() => {
                context.commit('show_timeout_warning', false);
            })
            .catch(err => {
                switch (err.response.data.result) {
                    case 'password_expired':
                        toast.warning('Password Expired!');
                        setTimeout(() => this.$router.push(`/change_password/${err.response.data.stateToken}`), 2000);
                        break;
                    case 'unauthorized':
                        toast.error("Username/Password not Recognized");
                        break;
                    default:
                        toast.error("Error not recognized.  Contact rick.gentry@crispin.com");
                        break;
                }
            });
    },
    refresh_token_and_timeouts(context, token) {
        if (!token) {
            if (router.currentRoute.value.name !== 'home') {
                const current_path = router.currentRoute.value.path;
                const route = {name: 'home'}
                if (current_path !== '/') {
                    route.query = {path: current_path}
                }
                router.replace(route);
            }
            return;
        }
        context.commit('set_token', token);

        clearTimeout(context.state.logout_warning_timeout);
        clearTimeout(context.state.logout_timeout);
        const time_until_exp = Math.max(context.state.data.exp * 1000 - Date.now(), 0);

        let logout_timeout = null;
        let logout_warning_timeout = null;
        if (time_until_exp < 0) {
            context.dispatch('logout', {preserve_path: true});
            return;
        } else {
            logout_timeout = setTimeout(() => context.dispatch('logout', {preserve_path: true}), time_until_exp);
        }
        if (time_until_exp < 60 * 1000) {
            context.commit('show_timeout_warning', true);
        } else {
            logout_warning_timeout = setTimeout(() => context.commit('show_timeout_warning', true), time_until_exp - 60 * 1000);
        }

        context.commit('set_timeouts', {logout_warning_timeout, logout_timeout})
    },
    logout(context, {preserve_path = false} = {}) {
        clearTimeout(context.state.logout_warning_timeout);
        clearTimeout(context.state.logout_timeout);
        context.commit('show_timeout_warning', false)
        context.commit('set_token', null);

        const route = {name: 'home'};
        const current_path = router.currentRoute.value.path;
        if (current_path && preserve_path) {
            route.query = {path: current_path}
        }
        auth_api.post("/api/user/logout")
            .then(() => router.push(route))
            .catch(() => router.push(route));
    }
}

const mutations = {
    set_token(state, token) {
        if (token) {
            localStorage.setItem(local_storage_token_key, token);
            state.token = token;
            state.data = token && parseJwt(token);
        } else {
            localStorage.removeItem(local_storage_token_key);
            state.token = null;
            state.data = null;
        }
    },
    set_change_password_token(state, change_password_token) {
        state.change_password_token = change_password_token;
    },
    show_timeout_warning(state, show_timeout_warning) {
        state.show_timeout_warning = show_timeout_warning;
    },
    set_timeouts(state, {logout_warning_timeout, logout_timeout}) {
        state.logout_warning_timeout = logout_warning_timeout;
        state.logout_timeout = logout_timeout;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}