import {createApp} from 'vue'
import App from './App.vue'

import store from "@/store";
import router from "@/router";
import Toast, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";

import "@/assets/fontawesome/css/all.css";
import "@/assets/theme-core.less";
import "@/assets/theme-buttons.less";
import "@/assets/theme-controls.less";
import "@/assets/theme-tables.less";

document.title = 'Crispin Data+Analytics';

const app = createApp(App)
app.use(store);
app.use(router);
app.use(Toast, {
    position: POSITION.TOP_CENTER,
    maxToasts: 3,
    hideProgressBar: true,
    pauseOnHover: true,
    showCloseButtonOnHover: true
});
app.config.unwrapInjectedRef = true;

Promise.all([
    store.dispatch('auth/init')
]).then(() => app.mount('#app'));
