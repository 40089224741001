import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";

const routes = [
    {
        path: '/',
        component: () => import('@/components/RouterPassthrough'),
        meta: {nav_pill: '<i class="fas fa-home"></i> Home'},
        children: [
            {
                name: 'home',
                path: '',
                component: () => import('@/views/Root'),
                meta: {title: () => "Crispin Data+Analytics"},
            },
            {
                path: ':brand_context_slug',
                props: true,
                component: () => import('@/components/BrandPassthrough'),
                meta: {brand_aware: true},
                children: [
                    {
                        path: 'keyword',
                        component: () => import('@/views/Keywords'),
                        name: 'keywords',
                        meta: {nav_pill: 'Landscape Keywords', title: () => 'Crispin/Landscape Keywords', tool: 'keywords'}
                    },
                    {
                        path: 'pixel',
                        component: () => import('@/views/TrackingPixels'),
                        name: 'pixels',
                        meta: {nav_pill: 'Landscape Keywords', title: () => 'Crispin/Tracking Pixels', tool: 'pixels'}
                    },
                ]
            },
            {
                path: 'service_monitoring',
                component: () => import('@/components/RouterPassthrough'),
                meta: {nav_pill: 'Service Monitoring', tool: 'service_monitoring'},
                children: [
                    {
                        path: '',
                        component: () => import('@/views/ServiceMonitoringSummary'),
                        name: 'service_monitoring',
                        meta: {title: () => "Service Monitoring"}
                    },
                    {
                        path: 'edit',
                        component: () => import('@/views/ServiceMonitoringEdit'),
                        name: 'service_monitoring-edit',
                        meta: {title: () => "Service Monitoring - Edit Structure"}
                    },
                    {
                        path: 'logs/:stack_name/:service_name',
                        props: true,
                        component: () => import('@/views/ServiceMonitoringLogs'),
                        name: 'service_monitoring-logs',
                        meta: {title: (route) => `Service Monitoring - Logs ${route.params.stack_name}/${route.params.service_name}`}
                    },
                    {
                        path: 'failures/:stack_name/:service_name',
                        props: true,
                        component: () => import('@/views/ServiceMonitoringFailures'),
                        name: 'service_monitoring-failures',
                        meta: {title: (route) => `Service Monitoring - Failures ${route.params.stack_name}/${route.params.service_name}`}
                    }
                ]
            },
            {
                path: 'brand',
                component: () => import('@/components/RouterPassthrough'),
                meta: {nav_pill: 'Brands', tool: 'brands'},
                children: [
                    {
                        path: '',
                        component: () => import('@/views/Brands'),
                        name: 'brand_view',
                        meta: {title: () => "Crispin/Brands"},
                    },
                    {
                        path: '/master_brand',
                        component: () => import('@/views/MasterBrands'),
                        name: 'master_brand_view',
                        meta: {title: () => "Crispin/Master Brands"}

                    }
                ]
            },
            {
                path: 'user',
                component: () => import('@/views/Users'),
                name: 'user',
                meta: {nav_pill: 'Users', title: () => "Crispin/Users", tool: 'users'}
            },
            {
                path: ':pathMatch(.*)*',
                component: () => import('@/views/NotFound'),
                name: '404',
                meta: {title: () => "404 Not Found"}
            },
        ]
    }
]

export const history = createWebHistory();

const router = createRouter({history, routes});

router.beforeEach((to) => {
    const auth = store.state.auth.token;
    if (!auth && to.name !== 'home') {
        return {name: 'home'};
    }
    for (const match of to.matched) {
        if (match.meta && match.meta.tool && !store.state.auth.data.permissions.tools.includes(match.meta.tool)) {
            return {name: 'home'};
        }
    }
});

router.afterEach((to) => {
    try {
        document.title = to.meta.title && to.meta.title(to);
    } catch (e) {
        document.title = "Crispin Data+Analytics";
    }
})

export default router;
