<template>
  <div v-if="show_modal">
    <Teleport to="body">
      <TransitionFade>
        <div class="modal">
          <div class="header">
            <slot name="header"></slot>
            <button class="link close-button" @click="show_modal=false"><i class="fas fa-times"/></button>
          </div>
          <div class="body">
            <slot name="body"></slot>
          </div>
          <div class="footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </TransitionFade>
      <TransitionFade>
        <div class="modal-close-detect" @click="show_modal = false"/>
      </TransitionFade>
    </Teleport>
  </div>
</template>

<script setup>
import {inject, defineProps} from "vue";
import TransitionFade from "@/components/TransitionFade";

const props = defineProps(['show_modal']);
const show_modal = inject(props.show_modal ?? 'show_modal');
</script>

<style lang="less">
@import "@/assets/theme-vars";

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  flex-direction: column;
  min-height: 25vh;
  max-height: 100vh;
  min-width: 25vw;
  z-index: @modal_z_index;

  .header {
    background-color: @black;
    color: @white;
    position: relative;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem 1rem;

    .close-button {
      position: absolute;
      top: .4rem;
      right: .4rem;
      color: @white;
    }
  }

  .body {
    background-color: @white;
    color: @black;
    flex-direction: column;
    overflow: auto;
    padding: 1rem;
    gap: .5rem;
  }

  .footer {
    background-color: @white;
    padding: .5rem;
    gap: .25rem;
    flex: 0 1 0;
    justify-content: flex-end;
  }
}

.modal-close-detect {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: @menu_close_z_index;
}
</style>