<template>
  <TransitionFade>
    <div class="app-top-bar" v-if="show">
      <div class="bar-container">
        <div class="bar-section">
          <div class="logo-container">
            <Logo color="white"/>
            <span>Data+Analytics</span>
          </div>
        </div>
        <div class="bar-section"></div>
        <div class="bar-section options">
          <a href="mailto:rick.gentry@crispin.com?subject=DNA Support">Support <i class="fa fa-face-grimace"/></a>
          <div class="menu-chevron" style="cursor: pointer" @click="showMenu = !showMenu">
            <i class="fa fa-chevron-down" :class="{'fa-chevron-down': !showMenu, 'fa-chevron-up': showMenu}"></i>
          </div>
        </div>
      </div>
      <TransitionFade>
        <div class="menu" v-if="showMenu">
          <router-link class="menu-item" v-for="tool in context_free_tools" :key="tool.key" :to="tool.url" v-html="tool.name"/>
          <router-link class="menu-item" v-for="tool in brand_context_tools" :key="tool.key" :to="tool.url" v-html="tool.name"/>
          <router-link class="menu-item" v-for="tool in admin_tools" :key="tool.key" :to="tool.url" v-html="tool.name"/>
          <div class="menu-item" style="cursor: pointer" @click="showMenu = false; store.dispatch('auth/logout');">
            <span>Log Out <i class="fas fa-sign-out"></i></span>
          </div>
        </div>
      </TransitionFade>
      <TransitionFade>
        <div class="menu-close-detect" v-if="showMenu" @click="showMenu = false"/>
      </TransitionFade>
    </div>
  </TransitionFade>
</template>

<script setup>
import {computed, ref, watch} from "vue";
import {useStore} from "vuex";
import TransitionFade from "@/components/TransitionFade";
import {useRoute, useRouter} from "vue-router";
import Logo from "@/components/Logo.vue";

const route = useRoute();
const router = useRouter();
const store = useStore();

const brand_context = computed(() => store.state.brands.brand_context);
const brand_context_tools = computed(() => store.getters["tools/user_brand_context_tools"].filter(t => t.url.params.brand_context_slug !== 'null'));
const context_free_tools = computed(() => store.getters["tools/user_context_free_tools"]);
const admin_tools = computed(() => store.getters["tools/user_admin_tools"]);

const menu = ref(null);

const show = computed(() => store.state.auth.token);
const showMenu = ref(false);
watch(route, () => showMenu.value = false);
</script>

<style scoped lang="less">
@import "@/assets/theme-vars";

@transition_duration: .5s;

.app-top-bar {
  background-color: @blackish;
  color: @white;
  width: 100%;
  flex: 0 1 0;
  position: relative;

  .bar-container {
    width: 100%;

    .bar-section {
      flex: 1 0 0;

      .logo-container {
        justify-content: start;
        align-items: start;
        padding: 1rem;
        flex-direction: column;

        embed {
          height: 3rem;
        }

        span {
          align-self: center;
        }
      }

      &.options {
        justify-content: end;
        align-items: center;
        margin: 1rem;
        font-size: 2rem;
        gap: 3rem;

        a {
          color: white;
          font-size: 1rem;
        }

      }

      .options-container {
        justify-content: end;
        align-items: center;
        margin: .5rem 2rem .5rem .5rem;
      }
    }
  }

  .menu {
    position: absolute;
    right: 0;
    top: calc(100%);
    display: grid;
    z-index: @menu_z_index;

    .menu-item {
      padding: .5rem;
      border: 1px solid @gray;
      background-color: @blackish;
      color: @whitish;
    }
  }

  .menu-close-detect {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: @menu_close_z_index;
  }
}


</style>