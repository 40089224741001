import axios from "axios";
import {auth_api, blocking_auth_api} from "@/axios";
import {useToast} from "vue-toastification";

const toast = useToast();

const state = () => ({
    monitor_structure: null,
    alerts: null,
    queue_lengths: null,
    alerted_structure: null
})

const getters = {}

const actions = {
    load({commit, dispatch, state}) {
        if (state.monitor_structure)
            return
        return axios.all([
            auth_api.get("/api/service_monitoring/structure")
                .then(resp => commit('set_monitor_structure', resp.data)),
            auth_api.get("/api/service_monitoring/alerts")
                .then(resp => commit('set_alerts', resp.data)),
            auth_api.get("/api/service_monitoring/queues")
                .then(resp => commit('set_queue_lengths', resp.data))
        ]).then(() => {
            dispatch('apply_alerts')
        })
    },
    refresh({commit, dispatch}) {
        return auth_api.get("/api/service_monitoring/alerts")
            .then(resp => commit('set_alerts', resp.data))
            .then(() => dispatch('apply_alerts'));
    },
    apply_alerts({state, commit}) {
        const alerted_structure = JSON.parse(JSON.stringify(state.monitor_structure));
        alerted_structure.stacks = alerted_structure.stacks.filter(s => s.active).map(stack => {
            stack.services = stack.services.filter(s => s.active)
            return stack
        })
        for (const alert of state.alerts) {
            const item = alert.service ?
                alerted_structure
                    .stacks.find(s => s.name === alert.stack)
                    .services.find(s => s.name === alert.service) :
                alerted_structure
                    .stacks.find(s => s.name === alert.stack);
            item.alert = true;
            item.alert_messages = alert.messages;
        }
        commit('set_alerted_structure', alerted_structure)
    },
    save_stack({commit, state}, stack) {
        commit('save_stack', stack);
        return blocking_auth_api.post('/api/service_monitoring/structure', state.monitor_structure);
    },
    delete_stack({commit, state}, stack) {
        commit('delete_stack', stack);
        return blocking_auth_api.post('/api/service_monitoring/structure', state.monitor_structure);
    },
    save_service({commit, state}, {stack, service}) {
        commit('save_service', {stack, service});
        return blocking_auth_api.post('/api/service_monitoring/structure', state.monitor_structure);
    },
    delete_service({commit, state}, {stack, service}) {
        commit('delete_service', {stack, service});
        return blocking_auth_api.post('/api/service_monitoring/structure', state.monitor_structure);
    },
    // TODO "IGNORE ERROR" RULES
}

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

function insert(list, object) {
    if (list.find(x => x.name === object.name && x.id !== object.id)) {
        toast.error("Name already exists")
        return;
    }
    if (!object.id) {
        object.id = uuidv4();
    }
    const idx = list.findIndex(s => s.id === object.id);
    if (idx >= 0)
        list.splice(idx, 1, object);
    else
        list.push(object);
    list.sort((a, b) => a.name < b.name ? -1 : 1);
}

const mutations = {
    set_monitor_structure(state, structure) {
        state.monitor_structure = structure;
    },
    set_alerts(state, alerts) {
        state.alerts = alerts;
    },
    set_queue_lengths(state, queue_lengths) {
        state.queue_lengths = queue_lengths;
    },
    set_alerted_structure(state, structure) {
        state.alerted_structure = structure;
    },
    save_stack(state, stack) {
        insert(state.monitor_structure.stacks, stack)
    },
    delete_stack(state, stack) {
        const idx = state.monitor_structure.stacks.findIndex(s => s.id === stack.id);
        state.monitor_structure.stacks.splice(idx, 1);
    },
    save_service(state, {stack, service}) {
        insert(state.monitor_structure.stacks.find(s => s.name === stack.name).services, service)
    },
    delete_service(state, {stack, service}) {
        const ref_stack = state.monitor_structure.stacks.find(s => s.name === stack.name);
        const idx = ref_stack.services.findIndex(s => s.name === service.name);
        ref_stack.services.splice(idx, 1);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}