import {auth_api, blocking_auth_api} from "@/axios";
import {obj_to_slug} from "@/util";


const state = () => ({
    brands: null,
    master_brands: null,
    brand_context: null,
    property_types: null,
    master_brand_types: null
})

const getters = {}

export const brand_transform = brand => ({
    ...brand,
    display: `${brand.name} (${brand.country})`,
    slug: obj_to_slug(brand.id, `${brand.name}(${brand.country})`)
})

export const master_brand_transform = master_brand => ({
    ...master_brand,
    slug: obj_to_slug(master_brand.id, master_brand.name)
});

const actions = {
    load_brands({commit, state}, {blocking} = {blocking: false}) {
        if (state.brands)
            return
        return (blocking ? blocking_auth_api : auth_api).get("/api/brand").then(resp => {
            commit('set_brands', resp.data.map(brand_transform));
        })
    },
    load_master_brands({commit, state}, {blocking} = {blocking: false}) {
        if (state.master_brands)
            return;
        return (blocking ? blocking_auth_api : auth_api).get("/api/master_brand").then(resp => {
            commit('set_master_brands', resp.data.map(master_brand_transform));
        })
    },
    load_property_types({commit, state}, {blocking} = {blocking: false}) {
        if (state.property_types)
            return;
        return (blocking ? blocking_auth_api : auth_api).get("/api/brand/property_types").then(resp => {
            commit('set_brand_property_types', resp.data);
        })
    },
    load_master_brand_types({commit, state}, {blocking} = {blocking: false}) {
        if (state.master_brand_types)
            return;
        return (blocking ? blocking_auth_api : auth_api).get("/api/master_brand/types").then(resp => {
            commit('set_master_brand_types', resp.data);
        })
    },
}

const mutations = {
    set_brands(state, brands) {
        state.brands = brands;
    },
    set_brand_context(state, brand_context) {
        state.brand_context = brand_context;
    },
    set_master_brands(state, master_brands) {
        state.master_brands = master_brands;
    },
    set_brand_property_types(state, property_types) {
        state.property_types = property_types;
    },
    set_master_brand_types(state, master_brand_types) {
        state.master_brand_types = master_brand_types;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}