<template>
  <Transition appear mode="out-in" name="fade">
    <slot/>
  </Transition>
</template>

<script setup>
</script>

<style>
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}
</style>